<template>
    <div id="comment-ticketing" class="comment" :class="{ new: comment.status == 2, portrait: ['portrait'].includes($mq) }">
        <div class="meta">
            <div class="image">
                <img v-if="comment.isFromUser && !fromEmail" class="main-img" v-bind:src="employee ? employee.avatar : '~@/../img/empty_avatar.svg'" />
                <div v-else-if="comment.isFromUser && fromEmail" class="email-user"></div>
                <div v-else class="andy-team"></div>
            </div>
            <div class="info" :class="{ new: comment.status == 2 }">
                <div class="user">
                    <div v-if="!fromEmail" class="name">{{ comment.isFromUser ? (employee ? `${employee.name} ${employee.surname}` : $t('tickets.unknown_user')) : $t('tickets.detail.andy-team') }},</div>
                    <div v-else class="name">{{ location.name }},</div>

                    <div class="date" v-if="comment.createdTime">{{ formatDate(comment.createdTime) }}</div>
                </div>
                <div v-if="comment.status == 2" class="new-box">
                    <div class="msg">Nuevo mensaje</div>
                </div>
            </div>
        </div>
        <div class="msg hide-scrollbar" v-html="descriptionParse(comment.summary)"></div>
    </div>
</template>

<script>
export default {
    name: 'CommentTicket',
    props: {
        comment: { type: Object, default: '' }
    },
    data() {
        return {
            fromEmail: false
        }
    },
    computed: {
        employee() {
            let employee = this.$store.getters['employee/getEmployee'](this.comment.author)
            return typeof employee !== 'undefined' ? employee : false
        },
        location() {
            let location = this.$store.getters['loginUser/getLocalLocation']
            return location
        }
    },
    methods: {
        formatDate(created_date) {
            let date = moment(created_date * 1000)
            let result = moment(date).format('DD MMM HH:mm')
            return result
        },

        descriptionParse(description) {
            if (description) {
                var result = description
                result = result.replace(/\n/gi, ' <br> ')
                result = result.replace('<a', '<a target=_blank')
                result = result.replace(/([^\S]|^)(((https?\:\/\/)|(http?\:\/\/)|(www\.))(\S+))/gi, function(match, space, url) {
                    var hyperlink = url.startsWith('www') ? 'https://' + url : url
                    return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
                })
                return result
            } else return ''
        }
    },
    created() {
        // check if comment is from email
        if (this.comment.isFromUser && validEmail(this.comment.author)) {
            this.fromEmail = true
        }
    }
}
</script>

<style lang="scss">
#comment-ticketing {
    @include display-flex();
    @include justify-content(flex-start);
    @include align-items(flex-start);
    @include flex-wrap(wrap);
    @include background($color: $inactive-t90);
    @include border-radius(4px);
    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba(114, 116, 119, 0.1));
    height: auto;
    width: 100%;
    padding: 9px;
    margin-bottom: 30px;

    .meta {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-items();
        @include align-content();
        width: 100%;
        padding-bottom: 6px;
        margin-right: 6px;

        .image {
            height: 20px;
            width: 20px;

            .andy-team {
                @include border-radius(50%);
                @include background($color: $mono-2-light, $image: img('support_team_ffffff.svg'), $size: 13px, $position: center center);
                width: 100%;
                height: 100%;
            }

            .email-user {
                @include border-radius(50%);
                @include background($image: img('empty_avatar.svg'), $size: cover, $position: center center);
                width: 100%;
                height: 100%;
            }
        }
        .info {
            width: 100%;
            padding-left: 5px;

            .user {
                @include display-inline-flex();
                @include justify-content(flex-start);
                @include align-items();
                @include flex-wrap(wrap);
                width: 100%;

                .name {
                    @include text-ellipsis();
                    @include font-size(sm);
                    color: $neutro-s90;
                    max-width: 50%;
                    // margin-right: 15px;
                }

                .date {
                    @include text-ellipsis();
                    @include font-size(m);
                    color: $neutro-s90;
                    padding-left: 5px;
                    max-width: 50%;
                }
            }

            &.new {
                .user {
                    width: calc(100% - 150px);
                }

                .new-box {
                    @include display-inline-flex();
                    @include justify-content(flex-end);
                    width: 150px;

                    .msg {
                        @include display-inline-flex();
                        @include background($color: $warning);
                        @include border-radius(3px);
                        @include text-ellipsis();
                        @include font-size(xs);
                        max-width: 150px;
                        width: fit-content;

                        font-family: $text-bold;
                        color: #fff;
                    }
                }
            }
        }
    }

    .msg {
        @include font-size(m);
        font-family: $text !important;
        color: $neutro-s70;
        padding: 0px 8px;
        overflow: auto;
        overflow-y: hidden;

        * {
            font-family: $text !important;
            font-size: inherit;
        }

        b,
        strong {
            font-family: $text-bold !important;
            font-size: inherit;
        }

        a {
            color: $main-t20;
            text-decoration: underline;
            font-size: inherit;
        }

        img {
            object-fit: contain;
            object-position: center;
        }
    }
}

// }
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#comment-ticketing.portrait {
    box-shadow: none;
    margin-bottom: 20px;

    .meta {
        .image {
        }
        .info {
            .user {
                .name {
                    @include font-size(xs);
                    font-weight: bold;
                    color: #333;
                }
                .date {
                    @include font-size(xs);
                }
            }
        }
    }
    .msg {
        @include font-size(s);
    }
}
</style>
