<template>
    <div class="ticketDetail hide-scrollbar" :class="[$mq]">
        <!-- NEW CODE -->
        <template v-if="!['portrait'].includes($mq) || !ticket">
            <div class="column">
                <div class="label">{{ $t('tickets.consults') }}</div>
                <div class="tickets hide-scrollbar">
                    <template v-for="ticket in tickets">
                        <ticketItem :key="ticket.id" :ticket="ticket" :statusColor="getStatusColor(ticket.data.status)" @openTicket="openTicket($event)" :compressed="true" :selected="ticket.id == $route.params.id"></ticketItem>
                    </template>
                </div>
            </div>
        </template>
        <div class="content hide-scrollbar">
            <!-- TEMPLATE OF UNSELECTED ITEM / VIEW ALL TICKETS -->
            <template v-if="!ticket">
                <div class="empty">{{ $t('tickets.empty_detail') }}</div>
            </template>
            <!-- TEMPLATE VIEW OF A TICKET -->
            <template v-else>
                <div class="anchor-msg " @click="seeFullDescription = !seeFullDescription">
                    <div class="msg" :class="{ full: seeFullDescription }">
                        {{ ticket.description }}
                    </div>
                    <div class="meta">
                        <UserTag v-if="!userName" :userId="ticket.employee_id" :size="'bold'"></UserTag>
                        <div class="date">
                            <template v-if="!['portrait'].includes($mq)">{{ $t('tickets.consult_the') }}</template> {{ createdDate }}
                        </div>
                    </div>
                </div>

                <div class="chat" :class="{ closed: closed }">
                    <!-- CHAT WITH MESSAGES -->
                    <template>
                        <div class="comments scrollbar scrollbar-transparent" :class="{ 'hide-scrollbar': ['portrait'].includes($mq) }" :key="lastThread">
                            <template v-if="Object.keys(threads).length > 0">
                                <template v-for="comment in Object.values(threads)">
                                    <CommentTicket :key="comment.id" :comment="comment"></CommentTicket>
                                </template>
                                <template v-if="resolution && !closed">
                                    <CommentTicket :comment="resolution"></CommentTicket>
                                    <div class="ticket-closed">{{ $t('tickets.ticket_closed') }}</div>
                                </template>
                                <Message v-if="closed" :message="$t('tickets.closed_message')" :type="'info'" :hasActions="false"></Message>
                                <Message v-else-if="!closed && Object.keys(threads).length > 0 && Object.values(threads)[Object.keys(threads).length - 1] && Object.values(threads)[Object.keys(threads).length - 1].isFromUser" :message="$t('tickets.thanks_for_message', { s: ticket.email })" :type="'info'" :hasActions="false"></Message>
                            </template>
                            <template v-else>
                                <Message v-if="!closed" :message="$t('tickets.thanks_for_message', { s: ticket.email })" :type="'info'" :hasActions="false" :portraitDeployed="true" customClass="not-collapse"></Message>
                            </template>
                            <!-- INPUT TO SEND A NEW MESSAGE -->
                            <div class="write" v-if="!closed">
                                <div v-show="showInput" class="input" :class="{ hide: !showInput }">
                                    <!-- :placeholder="$t('tickets.detail.answer')"  -->
                                    <textarea ref="input" v-model.trim="message" :class="{ closed: closed }"></textarea>
                                </div>

                                <div class="button" @click="showInput ? sendMessage() : toogleInput()" :class="{ closed: closed }">
                                    <template v-if="showInput">
                                        {{ $t('tickets.new_message') }}
                                    </template>
                                    <template v-else>
                                        {{ $t('tickets.new_a_message') }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Message from '@/components/domain/ui/Message'
import UserTag from '@/components/layout/UserTag'
import CommentTicket from '@/components/domain/ticket/comment'
import ticketItem from '@/components/domain/ticket/item'

export default {
    name: 'ticketDetail',
    components: {
        Message,
        UserTag,
        CommentTicket,
        ticketItem
    },
    props: {
        statusName: { type: String, default: '' },
        statusColor: { type: String, default: '' }
    },
    data() {
        return {
            message: '',
            closed: false,
            resolution: false,
            userName: false,

            seeFullDescription: false,
            showInput: false
        }
    },
    computed: {
        createdDate() {
            let date = moment(this.ticket.created_date * 1000)
            let result = moment(date).format('DD MMM HH:mm')
            return result
        },

        location() {
            return this.$store.getters['loginUser/getLocalLocation']
        },

        ticket() {
            if (this.$route.params.id && this.$route.params.id != 'all') {
                let ticket = this.$store.getters['ticket/getById'](this.$route.params.id)
                this.showInput = false
                this.message = ''
                return ticket
            } else return false
        },

        tickets() {
            return this.$store.getters['ticket/getList']
        },

        threads() {
            const ticketId = this.$route.params.id
            const threads = this.$store.getters['ticket/getThreads'](ticketId)
            return threads
        },
        lastThread() {
            const ticketId = this.$route.params.id
            const threads = this.$store.getters['ticket/getThreads'](ticketId)
            const sLastThreadId = Object.keys(threads)[Object.keys(threads).length - 1]
            const result = typeof sLastThreadId !== 'undefined' ? sLastThreadId : -1
            return result
        },
        statusList() {
            let status = {
                all: {
                    key: 'all',
                    name: 'tickets.status.all',
                    color: false
                },
                Open: {
                    // EN GESTION
                    key: 'Open',
                    name: 'tickets.status.open',
                    color: '#ABBA37'
                },
                Hold: {
                    // RECIBIDA
                    key: 'Hold',
                    name: 'tickets.status.hold',
                    color: '#475B96'
                },
                Closed: {
                    // RESUELTA
                    key: 'Closed',
                    name: 'tickets.status.closed',
                    color: '#5FC09C'
                }
            }

            return status
        }
    },
    methods: {
        load() {
            // this.$store.dispatch('ticket/loadList', { location_id: this.location.id })
            this.resetActions()
            if (this.ticket) {
                if (this.ticket.data.status == 'Closed') {
                    // block chat
                    this.closed = true

                    // check if ticket has resolution
                    if (this.ticket.data.resolution) {
                        this.resolution = {
                            id: -1,
                            summary: this.ticket.data.resolution,
                            author: 'support@andyapp.zohodesk.eu',
                            createdTime: false,
                            isFromUser: false,
                            new: false
                        }
                    }
                }

                // check if ticket has employee id
                if (!this.ticket.employee_id) {
                    this.userName = this.ticket.data.name
                }

                this.$store.dispatch('ticket/getTreads', { id: this.ticket.id })
            }
        },

        returnToList() {
            this.$emit('returnToList')
        },

        newTicket() {
            this.$emit('newTicket')
        },

        sendMessage() {
            // check if ticket is not closed
            if (!this.closed) {
                let self = this
                let employee_id = this.$store.getters['loginUser/getLocalEmployee']

                if (this.message != '') {
                    // show loader
                    self.$overlay.loading({
                        blocking: true
                    })

                    this.$store
                        .dispatch('ticket/addMessage', {
                            id: this.ticket.id,
                            employee_id: employee_id,
                            message: this.message
                        })
                        .then(function(response) {
                            // clean message
                            self.message = ''

                            // scroll to bottom
                            let container = document.querySelector('.comments')
                            container.scrollTop = container.scrollHeight

                            // close loader
                            self.showInput = false
                            self.$overlay.hide()
                        })
                }
            }
            this.showInput = false
        },
        openTicket($event) {
            if (this.tickets[$event].id != this.ticket.id)
                this.$router.push({
                    name: 'TicketsDetail',
                    params: { id: this.tickets[$event].id }
                })

            log('OPEN TICKET')
            this.$store.dispatch('ticket/getTreads', { id: this.tickets[$event].id })
        },

        resetActions() {
            let self = this

            // reset bar acction
            this.$bar.reset()

            // create ticket => show form
            if (!this.ticket) {
                this.$bar.addAction('create-ticket', {
                    callback: () => {
                        // self.$router.push({
                        //     name: 'TicketsForm'
                        // })
                        self.$emit('openForm')
                    },
                    label: i18n.t('tickets.add_ticket')
                })
            }
            // return
            this.$bar.addAction('back', {
                callback: () => {
                    self.returnToList()
                },
                label: self.$t('menu_aux.action.back'),
                customClass: 'left'
            })
        },

        toogleInput() {
            this.showInput = true
            this.$refs.input.focus()
        },

        getStatusColor(status) {
            return typeof this.statusList[status] !== 'undefined' ? this.statusList[status].color : this.statusList['Open'].color
        }
    },

    created() {
        this.load()
    },

    updated() {
        let container = document.querySelector('.comments')
        container ? (container.scrollTop = container.scrollHeight) : true
        this.resetActions()
    }
}
</script>

<style lang="scss">
.ticketDetail {
    padding: 12px 6px 9px 30px;
    margin-bottom: 40px;
    height: 100%;
    overflow: hidden;

    .column {
        @include box-shadow($h: 0px, $v: 0px, $b: 6px, $s: 0px, $c: rgba($neutro-s90, 0.25));
        width: 25%;
        height: 100%;
        border-radius: 5px;
        background-color: $inactive-t70;
        padding: 6px;
        overflow: auto;
        float: left;

        .label {
            @include font-size(sm);
            font-family: $text-bold;
            color: $neutro-s70;
            text-align: center;
            width: 100%;
            margin: 10px auto 15px;
            height: 20px;
        }

        .tickets {
            height: calc(100% - 45px);
            overflow: auto;
        }
    }

    .content {
        width: 75%;
        float: right;
        overflow: auto;
        overflow-x: hidden;
        height: 100%;
        position: relative;

        .anchor-msg {
            @include border-radius(4px);
            @include box-shadow($h: 0px, $v: 6px, $b: 10px, $s: 0px, $c: rgba(114, 116, 119, 0.15));
            position: absolute;
            padding: 9px;
            margin: 0 5%;
            width: calc(90% - #{$scroll-width});
            background-color: $inactive-t90;
            overflow: hidden;
            z-index: 2;

            .meta {
                @include display-flex();
                @include justify-content(flex-start);
                @include align-items();
                @include align-content();
                width: 100%;
                padding-bottom: 6px;

                .user-tag > *,
                .user-tag,
                .date {
                    @include display-inline-flex();
                    @include justify-content();
                    @include align-items();
                    @include align-content();
                    @include font-size(m);
                    color: $neutro-s90;
                    float: left;
                }

                .user-tag {
                    width: fit-content;
                    .avatar {
                        img {
                            height: 20px;
                            width: 20px;
                        }
                    }
                    .name {
                        padding-left: 0px;
                    }
                }

                .date {
                    height: 18px;
                    width: fit-content;
                    padding-left: 4px;
                    font-family: $text;
                }
            }

            .msg {
                @include font-size(m);
                padding: 0px 8px;
                font-family: $text;
                color: $neutro-s70;
                @include text-ellipsis($line: 2);

                &.full {
                    @include text-ellipsis($line: 20000);
                    white-space: initial;
                    height: fit-content;
                }
            }
        }

        .chat {
            height: calc(100% - 30px);
            width: 100%;
            overflow: hidden;
            margin: 0 auto;

            .comments {
                // @include hideScrollbar();
                height: calc(100% - 40px);
                overflow: auto;
                width: 90%;
                margin: 0 auto;
                padding: 10px 30px;
                padding-top: 120px;

                .ticket-closed {
                    @include font-size(m);
                    font-family: $conden;
                    color: $neutro-s70;
                    height: auto;
                    width: 100%;
                    margin-bottom: 10px;
                    padding: 10px;
                }
            }

            .write {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include flex-wrap();
                margin-top: 30px;
                width: 100%;

                .input {
                    @include background($color: $neutro-t90);
                    @include border-radius(4px);
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap();
                    @include font-size(sm);
                    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba(#727477, 0.25));
                    width: calc(100% - 200px);
                    float: left;
                    height: 75px;
                    margin-right: 20px;
                    transition: 0.2s;

                    // .leftPart {
                    //     width: calc(100% - 50px);
                    //     height: 100%;
                    textarea {
                        @include background($color: $neutro-t90);
                        width: 100%;
                        height: 100%;
                        border: none;
                        margin: 0;
                        &::placeholder {
                            @include font-size(sm);
                            font-family: $text;
                        }
                        // &.closed {
                        //     cursor: not-allowed;
                        //     opacity: 0.5;
                        // }
                    }
                    &.hide {
                        width: 0px;
                        margin-right: 0px;
                        transition: 0s;

                        textarea {
                            transition: 0s;
                            padding: 12px 0;
                            overflow: hidden;
                        }
                    }
                }

                // .rightPart {
                //     @include background($color: $neutro-t90);
                //     @include display-flex();
                //     @include justify-content();
                //     @include align-items();
                //     @include flex-wrap();
                //     width: 200px;
                //     height: 100%;

                .button {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap();
                    @include background($color: $done);
                    @include border-radius(6px);
                    @include font-size(m);
                    @include text-ellipsis();
                    font-family: $text-bold;
                    color: #fff;
                    padding: 6px 0;
                    text-align: center;
                    width: 180px;
                    cursor: pointer;
                }
            }

            &.closed {
                .comment {
                    opacity: 0.6;
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.ticketDetail.portrait {
    padding: 0px;

    .column {
        width: 100%;
        box-shadow: none;
        background-color: initial;
        padding: 10px;

        .label {
            margin-top: 0;
        }

        .tickets {
            padding-bottom: 50px;
        }
    }

    .content {
        width: 100%;

        .anchor-msg {
            width: 100%;
            margin: 0 auto;
            box-shadow: none;
            background-color: $default-bg-color;
            background-color: $default-sec-color;
            padding: 10px 15px;
            border-radius: 0;

            .meta {
                padding: 0;
                .name {
                    @include font-size(xs);
                    color: #333 !important;
                    font-family: $text;
                }
                .date {
                    @include font-size(xs);
                    line-height: 0.6rem;
                    color: #333;
                    margin-left: 5px;
                }
            }
            .msg {
                font-family: $text-bold;
                padding: 0;
                color: #333;
                @include font-size(sm);
            }
        }

        .chat {
            padding: 0 5px;

            .comments {
                width: 100%;
                padding: 0 10px;
                padding-top: 120px;
                padding-bottom: 15px;

                .write {
                    margin-top: 15px;
                    display: block;
                    .input {
                        width: 100%;
                        margin-bottom: 10px;
                        box-shadow: none;
                        textarea {
                            width: 100%;
                        }
                    }
                    .button {
                        width: 100%;
                        padding: 15px;
                    }
                }
            }
        }
    }
}
</style>
